import { Controller } from "stimulus"


export default class extends Controller {
  static values = {
    subscription: Object
  }
  static targets = [
    "pricingCard",        // 报价卡片
    "priceAmountLabel",   // 显示总价的标签
    "beansAmountLabel" ,   // 显示总塔米豆数量的标签
    "planIdInput",         // 选中的套餐ID输入框
    "quantityInput",       // 选中的子账号数量输入框
  ];
  connect(){
    this.element.addEventListener('Pricing:changed', this.onChanged.bind(this))
  }

  disconnect(){
    this.element.removeEventListener('Pricing:changed', this.onChanged.bind(this))
  }

  selectPricingCard(e){
    this.pricingCardTargets.forEach(card => card.classList.remove('selected'))
    e.currentTarget.classList.add('selected')
    this.subscriptionValue = e.currentTarget.value
  }

  get selectedPricingCard(){
    return this.pricingCardTargets.find(card => card.classList.contains('selected'))
  }

  onChanged(e){
    // console.log('pricing on changed', this.selectedPricingCard)
    if(e.target !== this.selectedPricingCard) return
    this.subscriptionValue = e.target.value
  }

  subscriptionValueChanged(){
    this.updateView()
  }

  updateView(){
    // console.log('pricing', this.subscriptionValue)
    this.priceAmountLabelTargets.forEach(ele=> ele.textContent = ' ' + this.subscriptionValue.priceAmount + " 元")
    this.beansAmountLabelTargets.forEach(ele=> ele.textContent = this.subscriptionValue.beanAmount)
    this.planIdInputTarget.value = this.subscriptionValue.planId
    this.quantityInputTarget.value = this.subscriptionValue.membersCount
  }
}
