import { Controller } from "stimulus"
import $ from "jquery"
export default class extends Controller {
  static targets = ["dotContainer", "beginDot", "tamigosGroup", "detailContent"]
  static values = { url: String, cpid: String}

  connect() {
    dogRunAnimate(this.tamigosGroupTarget, this.beginDotTarget.offsetLeft, 0, this.beginDotTarget)
    setDialogPosition(this.detailContentTarget, this.beginDotTarget.offsetLeft, this.dotContainerTarget.clientWidth)
  }

  move(e) {
    let tamigosGroup = this.tamigosGroupTarget
    let detailContent = this.detailContentTarget
    let dotContainer = this.dotContainerTarget
    let destination = e.currentTarget.offsetLeft
    let standDogDom = dotContainer.querySelector('li.stand_dog')
    let destination_date = e.currentTarget.getAttribute('data-time')
    let last_position = tamigosGroup.style.transform.replace(/[^0-9\-,.]/g, '')
    if(window.timeline_request) {
      // 销毁过期ajax请求
      window.timeline_request.abort()
    }
    window.timeline_request = $.ajax({
      type: "get",
      url: this.urlValue,
      contentType: "application/json",
      data: {date: destination_date, company_id: this.cpidValue},
      success: (resp) => {
      },
      error: (resp) => {
      },
    });
    detailContent.classList.add('d-none')
    dotContainer.querySelector('li.active').classList.remove('active')
    if(standDogDom){
      standDogDom.classList.remove('stand_dog')
    }
    e.currentTarget.classList.add('active')
    setDialogPosition(detailContent, destination, dotContainer.clientWidth)
    window.cancelAnimationFrame(stop)
    dogRunAnimate(tamigosGroup, destination, last_position, e.currentTarget)
  }

  moveright(e) {
    let nextNode = this.dotContainerTarget.querySelector('li.active').nextElementSibling
    if(nextNode.getAttribute('data-time')){
      nextNode.click()
    }
  }

  moveleft(e) {
    let prevNode = this.dotContainerTarget.querySelector('li.active').previousElementSibling
    if(prevNode.getAttribute('data-time')){
      prevNode.click()
    }
  }
}

// 动画主函数
function dogRunAnimate(animationDom, destination, last_position, targetPointDom) {
  let start;
  let step_distance;
  let tamigosGifOne = document.querySelector('.tamigos-group__image_01')
  let tamigosGifTwo = document.querySelector('.tamigos-group__image_02')
  let tamigosGifTrans = document.querySelector('.tamigos-group__image_transition')
  let tamigosDialogContent = document.querySelector('.js_timeline__content')
  let gifs = [tamigosGifOne, tamigosGifTwo, tamigosGifTrans, tamigosDialogContent, targetPointDom]
  let gif_timers = window.gif_timers || []
  last_position = parseInt(last_position) || 0

  if (last_position < destination) {
    dogGifRunBegin(gifs, gif_timers)
    tamigosGifOne.classList.remove("rotate-180-x");
    window.requestAnimationFrame(stepForward);
  } else if (last_position > destination) {
    dogGifRunBegin(gifs, gif_timers)
    tamigosGifOne.classList.add("rotate-180-x");
    window.requestAnimationFrame(stepBack);
  }
  // Back
  function stepBack(timestamp) {
    if(start === undefined) { start = timestamp; }
    const elapsed = timestamp - start;
    step_distance = last_position - 0.18 * elapsed
    animationDom.style.transform = 'translateX(' + Math.max(step_distance, destination) + 'px)';
    if (elapsed < 10000 && step_distance > destination) {
      // stop animation after 10 seconds
      window.stop = window.requestAnimationFrame(stepBack);
    } else {
      window.gif_timers = dogGifRunEnd(gifs)
    }
  }
  // Forward
  function stepForward(timestamp) {
    if(start === undefined) { start = timestamp; }
    const elapsed = timestamp - start;
    step_distance = last_position + 0.18 * elapsed
    animationDom.style.transform = 'translateX(' + Math.min(step_distance, destination) + 'px)';
    if (elapsed < 10000 && step_distance < destination) {
      // stop animation after 10 seconds
      window.stop = window.requestAnimationFrame(stepForward);
    } else {
      window.gif_timers = dogGifRunEnd(gifs)
    }
  }
}

// 跑步结束
function dogGifRunEnd(gifs){
  let gif_timers = []
  gifs[0].classList.add('fade-out')
  gif_timers[0] = setTimeout(function(){
    gifs[2].style.opacity = 1;
  }, 150)
  gif_timers[1] = setTimeout(function(){
    gifs[2].style.opacity = 0;
    gifs[1].classList.add('fade-in-out')
  }, 750)
  gif_timers[2] = setTimeout(function(){
    gifs[3].classList.remove('d-none')
    gifs[4].classList.add('stand_dog')
  },4000)
  return gif_timers
}

// 跑步开始
function dogGifRunBegin(gifs, gif_timers){
  gifs[0].classList.remove('fade-out')
  gifs[0].classList.add('fade-in')
  gifs[1].classList.remove('fade-in-out')
  if(gif_timers.length > 0){
    // 清除上一次的计时器
    gif_timers.forEach((element) => { 
      clearTimeout(element)
    })
  }
  gifs[2].style.opacity = 0;
}

// 对话框定位（动画结束后才会展示）
function setDialogPosition(dom, destination, container_width){
  let dialog_offset
  // 如果目的地左右预留的空间都是大于200，则往左偏移180px
  if(destination >= 200 && container_width - destination >= 200){
    dialog_offset = destination - 180
  // 如果目的地右预留的空间小于200，说明在最右侧
  }else if(container_width - destination < 200){
    dialog_offset = destination - 310
  // 目的地在最左侧
  }else{
    dialog_offset = 20
  }
  dom.style.transform = 'translateX(' + dialog_offset + 'px)';
}