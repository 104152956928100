import { Controller } from "stimulus";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

export default class extends Controller {
  static targets = ['link']
  static values = { 'url': String, 'position': String, 'arrow': Boolean, 'maxWidth': Number, 'trigger': String }

  connect(){
    let loading_html = `<span class="spinner-border mr-2" role="status"></span><span>加载中...</span>`
    let that = this
    this.tippy_instance_fetched = false
    this.tippy_instance = tippy(this.linkTarget, {
      theme: 'light',
      content: loading_html,
      allowHTML: true,
      trigger: 'click',
      placement: this.positionValue || 'bottom',
      interactive: true,
      appendTo: 'parent',
      arrow: this.arrowValue || false,
      maxWidth: this.maxWidthValue || "none",
      onShow(instance) {
        if(!that.tippy_instance_fetched) {
          that.fetch()
              .then((content) => {
                instance.setContent(content)
                that.tippy_instance_fetched = true
              })
              .catch(() => instance.setContent('出错了'))
        }
      }
    })
  }

  async fetch () {
    if (!this.remoteContent) {
      if (!this.hasUrlValue) {
        console.error('[stimulus-popover] You need to pass an url to fetch the popover content.')
        return
      }

      const response = await fetch(this.urlValue)
      this.remoteContent = await response.text()
    }

    return this.remoteContent
  }
}
