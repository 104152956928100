import { Controller } from "stimulus"
import UAParser from "ua-parser-js"
export default class extends Controller {

  connect() {
    var parser = new UAParser()
    if(parser.getDevice().type == "mobile" && !window.ReactNativeWebView){
      this.schema_url = 'tamigos://'
      this._openApp()
    }
  }

  _openApp(){
    let timer = 1000, t1 = Date.now()
    let ifr = document.createElement("iframe")
    ifr.setAttribute('src', this.schema_url)
    ifr.setAttribute('style', 'display:none')
    document.body.appendChild(ifr)
    setTimeout(() => {
      let t2 = Date.now()
      if (t2 - t1 < timer + 100) {
        ifr.remove()
      }
    }, timer)
  }
}
