import { Controller } from "stimulus";
// 上传分析报告
export default class extends Controller {
  static targets = ['form',
    'fileInput',
    'filenameInput',
    'encryptedIdInput',
    'attachmentInput',
    'filenameLabel',
    'progressBar',
    'clearButton',
    'submitButton']
  static values = {
    'tokenUrl': String
  }

  clear(){
    if(this.changing) return
    this.clearButtonTarget.classList.add('d-none')
    this.submitButtonTarget.disabled = true
    this.encryptedIdInputTarget.value = ''
    this.attachmentInputTarget.value = ''
    this.filenameLabelTarget.textContent = '选择文件'
    this.fileInputTarget.value = ''
    this.formTarget.reset()
    this.upload && this.upload.destroy()
    this.progressBarTarget.parentElement.style.opacity = 0;
    this.progressBarTarget.style.width= "0%"
  }

  onChange(evt){
    this.changing = true
    if(evt.target.files.length == 0){
      this.clear()
      this.changing = false
      return
    }
    this.changing = false
    this.submitButtonTarget.disabled = true
    this.submitButtonTarget.textContent = '上传中...'
    this.clearButtonTarget.classList.add('d-none')
    const file = evt.target.files[0]
    const beforeSend = (_file, fileKey) => {
      this.filenameInputTarget.value = fileKey
      this.progressBarTarget.parentElement.style.opacity = 1;
      this.progressBarTarget.classList.add('progress-bar-animated')
    }
    const progress = (num) => {
      this.progressBarTarget.style.width= num + "%"
    }
    const success = (result) => {
      this.clearButtonTarget.classList.remove('d-none')
      this.submitButtonTarget.disabled = false
      this.submitButtonTarget.textContent = '确认上传'
      this.progressBarTarget.classList.remove('progress-bar-animated')
      this.attachmentInputTarget.value = result.url
      this.encryptedIdInputTarget.value = result.encryped_id
      console.log(result);
    }
    const failed = (err) => {
      window.alert('上传失败，请关闭窗口后重新上传!')
    }
    this.filenameLabelTarget.textContent = file.name
    this.upload = new QiNiuBaseUpload({ file, tokenUrl: this.tokenUrlValue, beforeSend, progress, success, failed })
  }
}

