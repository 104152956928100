import { Controller } from "stimulus";
import { useDispatch } from "stimulus-use";

// 选择套餐的卡片容器
export default class extends Controller {
  static values = {
    membersCount: Number,     // 子账号数
    minMembersCount: Number,  // 允许设置的最小子账号数
    maxMembersCount: Number,  // 允许设置的最大子账号数
    timesToBean: Number       // 基于价格送塔米豆的倍数
  }
  static targets = [
    "membersCountLabel",       // 显示子账号数的标签
    "priceAmountLabel",       // 显示总价的标签
  ];
  initialize(){
    this.onPlanChangedInit = this.onPlanChanged.bind(this)
  }
  connect(){
    useDispatch(this, { eventPrefix: 'Pricing' })
    this.element.addEventListener('Plan:changed', this.onPlanChangedInit)
  }
  disconnect(){
    this.element.removeEventListener('Plan:changed', this.onPlanChangedInit)
  }
  // 增加子账号数
  increaseMembers(){
    if(this.membersCountValue >= this.maxMembersCountValue ){
      this.membersCountValue = this.maxMembersCountValue
      return
    }
    this.membersCountValue++
  }

  // 减少子账号数
  decreaseMembers(){
    if(this.membersCountValue <= this.minMembersCountValue ){
      this.membersCountValue = this.minMembersCountValue
      return
    }
    this.membersCountValue--
  }

  // 当选中的套餐发生变化时
  onPlanChanged(e){
    e.preventDefault()
    // console.log('pricingCard#onPlanChanged', e.detail)
    this.planId = e.detail.planId
    this.planPrice = e.detail.price
    this.calculateAndDispatchChangedEvent()
  }

  // 当子账号数发生变化时
  membersCountValueChanged(){
    if(this.hasMembersCountLabelTarget){
      this.membersCountLabelTarget.textContent = this.membersCountValue
    }
    this.calculateAndDispatchChangedEvent()
  }

  // 计算数据并触发 Pricing:changed 事件
  calculateAndDispatchChangedEvent(){
    let priceAmount = 0, beanAmount = 0

    priceAmount = this.planPrice * this.membersCountValue
    beanAmount = priceAmount * this.timesToBeanValue

    // console.log('pricingCard', "选择了套餐", this.planId)
    // console.log('pricingCard', "设置了子账号数", this.membersCountValue)
    // console.log('pricingCard', "总价", priceAmount)
    // console.log('pricingCard', "送塔米豆", beanAmount)

    this.element.value = {
      planId: this.planId,
      membersCount: this.membersCountValue,
      priceAmount,
      beanAmount
    }

    if(this.hasPriceAmountLabelTarget){
      this.priceAmountLabelTarget.textContent = priceAmount ? " " + priceAmount + " 元" : ''
    }

    if(this.dispatch){
      this.dispatch('changed', this.element)
    }else{
      setTimeout(() => {
        this.dispatch('changed', this.element)
      }, 100);
    }
  }
}
