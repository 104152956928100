import './styles.scss'
import { Controller, Application } from "stimulus";

class ComponentController extends Controller{
  static targets = ['content', 'modal']
  static values = { 'url': String }

  connect(){
    this.modal_instance_fetched = false
  }
  show(){
    // bootstrap Modal需使用jQuery控制
    $(this.modalTarget).modal('show')
    if(this.modal_instance_fetched) {
      return
    }
    this.fetch()
      .then((content) => {
        this.contentTarget.innerHTML = content
      })
      .catch(() => this.contentTarget.innerHTML = '出错了')
  }

  async fetch () {
    if (!this.remoteContent) {
      if (!this.hasUrlValue) {
        console.error('[stimulus-popover] You need to pass an url to fetch the popover content.')
        return
      }

      const response = await fetch(this.urlValue)
      this.remoteContent = await response.text()
    }

    return this.remoteContent
  }
}

const app = Application.start()
app.register('mp-modal-link', ComponentController)

