import 'select2/dist/js/select2.min'
import zh_cn from './zh-CN'
import 'select2/dist/css/select2.min.css'
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css'
import $ from 'jquery'

// https://github.com/select2/select2/issues/5370
// select2 目前是 4.0.13, 但是只有 4.0.2 没有中文输入的 bug，所以必须使用 4.0.2 版本，不能更新

$(function() {
  $.fn.select2.defaults.set('theme', 'bootstrap4')
  $.fn.select2.defaults.set('language', zh_cn)

  $('input[select2], select[select2]').select2()
})

// 解决 select2 在模态框中，显示不出列表的问题
$('body').on('remoteLoaded.bs.modal', '.modal', function() {
  $(this).find('input[select2], select[select2]').each(function() {
    var dropdownParent = $(document.body);
    if ($(this).parents('.modal.show:first').length !== 0)
      dropdownParent = $(this).parents('.modal.show:first');
    $(this).select2({
      dropdownParent: dropdownParent
    });
  });
});
