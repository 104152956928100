import { Controller } from "stimulus"
import $ from "jquery"
export default class extends Controller {
  static values = { url: String }

  connect() {
    setInterval(() => {
      requestData(this.urlValue)
    }, 600*1000);
  }

}
function requestData(url) {
  $.ajax({
    type: "get",
    url: url,
    contentType: "application/json",
    success: (resp) => {
    },
    error: (resp) => {
      console.log('error')
    },
  });
}
