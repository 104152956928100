import { Controller } from "stimulus";
import { VanillaScroll } from './scroll_to_controller';

export default class extends Controller {
  static targets = ['link']
  connect() {
    if(this.hasLinkTarget){
      this.spy = new VanillaScrollspy(this.linkTargets);
      this.spy.init();
    }
  }

  disconnect() {
    if(this.spy){
      this.spy.dispatch()
      this.spy = null
    }
  }
}

// 以下代码复制自
// https://github.com/ederssouza/vanillajs-scrollspy/blob/master/src/index.js


class VanillaScrollspy {
  constructor(links, speed = 2000, easing = 'easeOutSine') {
    this.$links = links;
    this.scroller = new VanillaScroll(speed, easing)
    this.clickHandler = this.clickHandler.bind(this)
    this.menuController = this.menuController.bind(this)
  }

  init() {
    Array.from(this.$links).forEach((link) => link.addEventListener('click', this.clickHandler));
    document.addEventListener('scroll', this.menuController);
  }

  dispatch() {
    document.removeEventListener('scroll', this.menuController)
    Array.from(this.$links).forEach((link) => link.removeEventListener('click', this.clickHandler));
  }

  menuController() {
    const scrollPos = window.scrollY || document.documentElement.scrollTop;
    const positions = Array.from(this.$links).map((link) => {
      const selector = link.getAttribute('href') || '';
      const match = selector.match(/^#(.+)/)
      if(!match) return
      const $elem = document.getElementById(match[1]);
      if(!$elem) {
        console.warn(`[scrollspy] element id=${match[1]} not found!`)
        return
      }
      // console.log('offset', match[1], scrollPos, $elem.offsetTop, $elem.clientHeight, $elem)
      return [link, $elem.offsetTop, parseInt($elem.dataset.scrollspyOffset) || 0]
    });
    const sortedPositions = positions.filter(item => item).sort(item => item[1]).reverse()
    var found = false
    sortedPositions.forEach(item => {
      if(!found && scrollPos >= item[1] + item[2]){
        item[0].classList.add('active')
        found = true
      }else{
        item[0].classList.remove('active')
      }
    })
  }

  clickHandler(e){
    e.preventDefault();
    const $target = document.querySelector(e.target.hash);
    this.scroller.scrollToY($target.offsetTop + (parseInt($target.dataset.scrollspyOffset) || 0));
  }
}
