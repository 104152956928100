import './styles.scss'
import { Controller, Application } from "stimulus";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
class ComponentController extends Controller{
  static targets = ['container', 'cardLink']
  static values = { 'url': String }

  connect(){
    let loading_html = `<span class="spinner-border mr-2" role="status"></span><span>加载中...</span>`
    this.tippy_instance = tippy(this.cardLinkTarget, {
      theme: 'light',
      content: loading_html,
      allowHTML: true,
      interactive: true,
      maxWidth: 580,
      onHidden(instance) {
        instance.setContent(loading_html)
      }
    })
    this.tippy_instance_fetched = false
  }
  show(){
    if(this.tippy_instance_fetched) {
      return
    }
    this.tippy_instance._isFetching = true
    this.fetch()
      .then((content) => {
        this.tippy_instance.setContent(content)
      })
      .catch(() => this.tippy_instance.setContent('出错了'))
  }

  hide(){
    this.element.classList.remove('open')
    this.element.classList.remove('loading')
  }

  async fetch () {
    if (!this.remoteContent) {
      if (!this.hasUrlValue) {
        console.error('[stimulus-popover] You need to pass an url to fetch the popover content.')
        return
      }

      const response = await fetch(this.urlValue)
      this.remoteContent = await response.text()
    }

    return this.remoteContent
  }
}

const app = Application.start()
app.register('mp-popover-link', ComponentController)

