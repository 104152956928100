/**
 * [make 构造DOM节点]
 * @param  {String} tagName           [description]
 * @param  {String} [classNames=null] [description]
 * @param  {Object} [attributes={}]   [description]
 * @return {element}                   [description]
 */
function make(tagName, classString = null, attributes = {}) {
  let el = document.createElement(tagName)

  let classArray = []
  if (Array.isArray(classString)) {
    classArray = classString
  } else {
    classArray = classString && classString.split(' ') || []
  }
  classArray = classArray.filter(function(e) {if (e) return e})
  el.classList.add(...classArray)

  for (let attrName in attributes) {
    el.setAttribute(attrName, attributes[attrName])
  }

  return el
}

export default { make }
