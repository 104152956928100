// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import $ from "jquery";
export default class extends Controller {
  static targets = ["mobilePhone", "timeCount", "message"];
  static values = { url: String };

  connect() {
    this.timer = null;
    this.seconds = 0;
    this.mobilePhoneTarget.placeholder =
      this.mobilePhoneTarget.placeholder || "请输入手机号";
  }
  //60s倒计时

  counterRunner() {
    if (this.seconds <= 0) {
      $(this.mobilePhoneTarget).removeAttr('readonly')
      $(this.timeCountTarget).removeAttr('disabled').removeClass('send-button-unused').html('重新发送');
      return;
    }

    this.seconds--;
    $(this.timeCountTarget).html("还剩" + this.seconds + "秒");
    this.timer = setTimeout(this.counterRunner.bind(this), 1000);
  }

  startCounter() {
    this.seconds = 60;
    if (this.timer) clearTimeout(this.timer);
    this.timer = null;
    $(this.mobilePhoneTarget).attr('readonly', true);
    $(this.timeCountTarget).attr('disabled', true).addClass('send-button-unused');
    this.counterRunner();
  }

  stopCounter() {
    $(this.mobilePhoneTarget).removeAttr('readonly');
    $(this.timeCountTarget).removeAttr('disabled').removeClass('send-button-unused').html('重发验证码');
  }

  send() {
    $(this.messageTarget).removeClass().html('')
    if (this.timer) clearTimeout(this.timer);

    Rails.ajax({
      type: "post",
      url: this.urlValue,
      data: $.param({ mobile_phone: this.mobilePhoneTarget.value }),
      success: (resp) => {
        this.messageTarget.innerHTML = resp.message;
        if (resp.code != 0) {
          $(this.messageTarget).addClass("alert alert-danger w-100");
        } else {
          this.startCounter();
          $(this.messageTarget).addClass("alert alert-success w-100");
        }
      },
      error: (resp) => {
        this.messageTarget.innerHTML = resp.message;
      },
    });
  }
}
