import { Controller } from "stimulus";
import { useDispatch } from "stimulus-use";

// 多个套餐的选择器
export default class extends Controller {
  static classes = [ "selected" ]
  static values = {
    id: String  // 默认选中的套餐ID
  }
  static targets = [
    "plan" // 套餐
  ];

  connect() {
    useDispatch(this, { eventPrefix: 'Plan' })
  }

  // 选中一个套餐
  select(e){
    this.idValue = e.currentTarget.dataset.planId
  }

  // 当选中的套餐ID改变时，设置当前选中的套餐样式，触发 Plan::changed 事件，返回 planId 和 price
  idValueChanged(){
    // console.log('idValue is', this.idValue)
    this.planTargets.forEach(plan => {
      // console.log('plan', plan.dataset.planId, this.idValue)
      if(plan.dataset.planId === this.idValue){
        this.price = parseFloat(plan.dataset.price)
        plan.classList.add(this.selectedClass)
      }else{
        plan.classList.remove(this.selectedClass)
      }
    })
    this.dispatchChangeEvent()
  }

  // 触发改变事件
  dispatchChangeEvent(){
    // console.log('select plan dispatchChangeEvent')
    if(this.dispatch){
      this.dispatch('changed', { planId: this.idValue, price: this.price })
    }else{
      setTimeout(() => {
        this.dispatch('changed', { planId: this.idValue, price: this.price })
      }, 100);
    }
  }
}
