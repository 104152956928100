import { Controller } from "stimulus";
import { FetchRequest } from '@rails/request.js';

export default class extends Controller {
  static targets = ["content"];
  static values = { baseUrl: String };

  connect() {
    this.hasLoaded = false
  }

  request() {
    if(!this.hasLoaded){
      this.myMethod()
    }
  }

  pageTurn(e) {
    e.preventDefault()
    e.stopPropagation()
    this.debounce(() => {
      if(e.target.hasAttribute('href')){
        const url = e.target.href
        this.myMethod(url)
      }
    }, 500, true)()

  }

  async myMethod (url = this.baseUrlValue) {
    const request = new FetchRequest('get', url, {})
    const response = await request.perform()
    if (response.ok) {
      this.hasLoaded = true
      const body = await response.text
      this.contentTarget.innerHTML = body
    }
  }

  debounce(func,wait,immediate) {

    let that = this
    return function () {
      let context = this;
      let args = arguments;

      if (that.timer) clearTimeout(that.timer);
      if (immediate) {
        var callNow = !that.timer;
        that.timer = setTimeout(() => {
          that.timer = null;
        }, wait)
        if (callNow) func.apply(context, args)
      } else {
        that.timer = setTimeout(function(){
          func.apply(context, args)
        }, wait);
      }
    }
  }
}
