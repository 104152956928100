// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import Popover from 'stimulus-popover'
import './pages/styles'
import "./styles"

const application = Application.start()
application.register('popover', Popover)

const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
