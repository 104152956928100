import '../public/select2_init'
import $ from 'jquery'
import ElementHelper from '../utils/element_helper'

$(function () {
  const body = $('body.channel_share.index')
  if (body.length > 0) {

    // select2 search
    const formatRepo = function (resource) {
      return resource.title || resource.text || ''
    }

    const userFormatSelection = function (resource) {
      const wrapper = ElementHelper.make('div')
      const textDom = ElementHelper.make('span')
      textDom.textContent = resource.title || resource.text
      wrapper.append(textDom)
      return wrapper
    }

    const selectDom = body.find("[select2='true']")
    selectDom.select2({
      minimumInputLength: 0,
      templateResult: formatRepo,
      templateSelection: userFormatSelection,
      ajax: {
        url: selectDom.data('search-url'),
        delay: 350,
        dataType: 'json',
        data: function (params) {
          return {
            search: {
              keywords: params.term
            },
            page: params.page
          }
        },
        processResults: function (data, params) {
          params.page = data.current_page || 1
          return {
            results: data.items,
            pagination: {
              more: data.current_page < data.total_pages
            }
          }
        },
        cache: true,
      },
      placeholder: selectDom.attr('placeholder'),
    })

  };
});

