import { Controller } from "stimulus";
import { get } from '@rails/request.js';

export default class extends Controller{
  static targets = [
    'modal',   // bootstrap 的 modal 节点
    'dialog',  // bootstrap modal 的 dialog 节点
    'loading'  // 加载动画
  ]

  async show (evt){
    evt.preventDefault()
    const target = evt.currentTarget
    const url = target.dataset.url || target.href
    $(this.modalTarget).modal('show')
    if(url){
      $(this.loadingTarget).show()
      $(this.dialogTarget).hide()
      const response = await get(url)
      if(response.ok){
        if(response.redirected){
          // console.log(response)
          // console.log(response.url)
          window.location.href = response.response.url
        }else{
          $(this.dialogTarget).html(await response.html)
        }
      }else{
        $(this.dialogTarget).html('出错了！')
      }

      $(this.loadingTarget).attr('style', 'display: none !important')
      $(this.dialogTarget).show()
    }
  }
}
