import { Controller } from "stimulus";
import 'jquery'
import 'social-share.js/dist/css/share.min.css';
import 'social-share.js/dist/js/jquery.share.min.js';

export default class extends Controller { 
  static targets = ["socialShareBox", "channelShare"]
  static values = { channelurl: String, url: String }

  initialize() {

  }
  connect() {
    if(this.urlValue.length > 0){
      var social_share_config = {
        sites: ['wechat', 'weibo', 'qq'],
        url: this.urlValue
      }
    }else{
      var social_share_config = {
        sites: ['wechat', 'weibo', 'qzone', 'qq']
      }
    }
    $(this.socialShareBoxTarget).share(social_share_config)
    $(this.socialShareBoxTarget).attr('data-initialized', true)
    // 使用js的方式生成分享icon
  }

  disconnect() {
  }
  share() {
    // 分享到专栏地址： https://www.tamigos.com/channel_share?url=URL&description=DESC&title=TITLE
    var target_url = this.channelurlValue
    var url = window.location.href
    var title = document.title.split("_")[0]
    var description = $('meta[name="description"]').attr("content");

    target_url = target_url.replace(/\b(URL|TITLE|DESC)\b/g, function($0, $1) {
        return {
            "URL": encodeURIComponent(url),
            "TITLE": encodeURIComponent(title),
            "DESC": encodeURIComponent(description)
        }[$1];
    })

    $(this.channelShareTarget).attr("href", target_url)
  }
}